/* stylelint-disable */
@import '../../Base/settings.scss';

.expandable-panel__info-block {
    &--dark {
        background-color: $expandable-bg-1;
    }
    
    .expandable-panel__info-block-content {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: column;
        align-items: flex-start;
        .expandable-panel__tabs-item{
            color: $custom-expandable-panel-title-text-color;
        }
    }

    .form-control {
        // width: 100%;

        label,
        .MuiInput-input {
            color: $navbar-menu-options-text;
        }
    }
}

.expandable-panel__actions-block {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: $grid-unit * 2;
}

.expandable-panel__tabs-item {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    // padding: $grid-unit * 3;    
    text-align: left;
    font: normal normal normal 12px/14px Roboto;
    letter-spacing: 0px;
    color: $title-color-1;
    opacity: 1;
}

.expandable-panel__actions-block {
    .form-control {
        flex: 1 1 auto;
    }

    > div:first-child {
        padding-right: 10px;
    }
}

.text-color--title-component {
    text-align: left;
    font: normal normal normal 12px/14px Roboto;
    letter-spacing: 0px;
    color: $title-color-1;
    opacity: 1;
}

.input-icon-journeys {
    padding-top: 16px;
    padding-right: 10px;
}

.journeys-padding-top {
    margin-top: -16px;
}

.expandable-panel-journeys {
    padding-left: 0%;
    padding-right: 0%;
}

.span-padding-journeys {
    padding-bottom: inherit;
}

.row_journeys {    
    text-align: right;
    font: normal normal normal 12px/14px Roboto;
    letter-spacing: 0px;
    color: $journey-report-tramo-text-color;
    background-color: $journey-report-tramo-background-color;
    opacity: 1;
    cursor: pointer;

    &.row_journeys--selected {
        background-color: $general-colors-selected;
        color: white;
    }
}

.padding-bottom-steps {
    margin-bottom: 10px;
}

.division-journeys-steps {
    padding-left: 16px;    
}

.division-journeys-steps-svg {
    height: 84px !important;
    margin-top: -27px;
}

.datos-journeys-steps {
    width: 100px !important;
}

.title-journeys-steps {
    text-align: left !important;
}

.datos-journeys-padding-steps {
    padding-top: 16px;
}

.icon-color-green {
    color: $icon-color-green;
}

.division-vertical-line-journeys-steps-svg {
    height: 84px !important;
    margin-top: -27px;
}

.vertical-line-journeys-steps {
    width: 17px;
    padding-left: 8px;
}

.day-step-pointer {
    cursor: pointer;
}

.day-step {

    &.day-step--selected {
        background-color: $daysteps-report-selected-title-background-color;
        color: $daysteps-report-selected-title-text-color;
    }
}

.dayStepContainer {
  max-height: 400px;
  overflow-y: scroll;

  & .MuiAccordionDetails-root{
      background-color: $daysteps-report-data-background-color!important;
      color: $daysteps-report-data-text-color!important;
  }

  & .row_journeys{
      background-color: $daysteps-report-data-step-background-color;
      color: $daysteps-report-data-step-text-color;
  }
}