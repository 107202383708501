@import "../../Theme";

/* stylelint-disable */

.checkboxSelectRow {
  padding: 0 !important;
}

.selection-cell > span[class*='MuiRadio-root'] {
  padding: 0 !important;
}

.dialogTitle {
  background-color: $modal-title-background-color;
  & .closeWindow{
    color: $modal-title-text-color;
    float: right;
  }
}

.dialogTitle > * {
  color: $modal-title-text-color;
}

.dialogContent,
.dialogActions{
  background-color: $modal-content-background-color!important;
  color: $modal-content-text-color!important;
}