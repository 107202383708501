/* stylelint-disable */
@import "../Base/settings";

$notification-icon-size: 6px;
$notification-icon-size-bg: 12px;

.notification-content {
  margin: 0 15px 0 15px;
}

.notification-content__row {
  align-items: center;
}

.MuiListItemText-root.notification-content-item {
  margin: 0;

  span {
    white-space: normal;
  }
}
.MuiListItemText-root.notification-content__description {
  margin: 0;
  color: $secondary-contrast;
  span {
    white-space: normal;
  }
}

.notification-icon {
  width: $notification-icon-size;
  height: $notification-icon-size;
  border-radius: 50%;
  background-color: black;
  flex: 0 0 auto;
}

.noticiation-icon-type {
  margin-right: $grid-unit * 4;
}

@each $status in $status-list {
  $i: index($status-list, $status);

  &.notification-icon-type--#{$status} {
    background-color: nth($status-list-badge, $i);
  }
}

.notification-icon-status {
  width: $notification-icon-size-bg;
  height: $notification-icon-size-bg;
  margin-left: $grid-unit * 4;
  background-color: $general-colors;
}

.notification-icon-status--read {
  background-color: #72829e;
}

.selectedNotification {
  background-color: $navbar-dropdown-notification-selected;
}

.flexrowCentered {
  justify-content: center;
}

.button-vertodos {
  color: $navbar-menu-options-text !important;
}

.no-notifications {
  justify-content: center;
  display: flex;
  width: 100%;
  color: $custom-app-base-text-color;
  font-weight: 500;
}
