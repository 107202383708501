/* stylelint-disable */
@import '../Base/settings.scss';

$layer-selector-size: 40px;
$controls-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);

/*.leaflet-control-layers {
  width: 44px !important;
  height: 44px !important;
}

.leaflet-control-layers-toggle {
  width: 38px !important;
  height: 40px !important;
}*/

.leaflet-touch .leaflet-control-layers-toggle {
  width: 40px !important;
  height: 40px !important;
}

/*.leaflet-touch .leaflet-control-layers-toggle {
  width: 44px !important;
  height: 44px !important;
}*/

/*.leaflet-control-layers {
    .leaflet-control-layers-list {
        bottom: 0;
        display: block;
        opacity: 1;
        position: absolute;
        right: 0;

        transition: all 1s ease-in-out;

        label {
            padding-top: $grid-unit * 3;
            cursor: pointer;

            div {
                background: white;
                border-radius: $grid-unit;
                border: 2px solid $brand-background;
                box-shadow: $controls-box-shadow;
                height: $layer-selector-size;
                width: $layer-selector-size;

                input {
                    opacity: 0;
                }

                span {
                    left: -9999999999999999px;
                    position: absolute;
                }
            }
        }

        .leaflet-control-layers-base__div--selected {
            border: $grid-unit solid $success-badge;
        }
    }

    &.leaflet-control-layers-expanded {
        padding: 0;
    }
}*/
