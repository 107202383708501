@import "../../Theme";

.pageTitleContainer {
  background-color: $page-title-background-color;
  color: $page-title-text-color;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  //font-weight: bold;
  font-style: normal;
  padding: 13.5px 0 13.5px 20px;
}

.customTotalDisplay {
  background-color: $page-title-total-display-background-color;
  border-radius: 5px;
  color: $page-title-total-display-text-color;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  padding: 5px;
}