/* stylelint-disable */
@import '../Base/settings.scss';
@import "../../Theme";

.groupButtonDropDown {
  min-height: 150px;
  max-height: 450px;
  overflow: scroll;
}

.dropdown-groups {
  min-height: 300px;
}

.searchResultContainer {
  margin-top: 20px;
  padding: 0 15px;
  width: 100%;
}

.searchList  {
  list-style-type: none;
  padding: 0 15px;
}

.groupsMenuContainer-header
{
  overflow: auto;
  min-height: 80px;
  padding-top: 10px;
}

.groupsMenuContainer {
  overflow: auto;
  min-height: 315px;
  padding-top: 10px;
  background-color: $brand-background;
}

.selectContainer {
  padding: 0px 15px 0px 15px;
  margin-top: 5px;
  width: 100%;
  height: auto;
}

.CustomerSelect__menu {
  position: unset !important;
}

.CustomerSelect__menu-list
{
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 312px;
  background-color: $brand-background;
}

.CustomerSelect__option 
{
  color: $customer-search-item-text-color!important;
  background-color: $customer-search-item-background-color!important;
  border: 1px solid #6f6f6f;
  background-color: $brand-background;
  box-shadow: 0 0 4px $navbar-background2;
  z-index: 1;
  display: none;
}

.CustomerSelect__option--is-focused
{
  background-color: $customer-search-item-hover-background-color!important;
  color: $customer-search-item-hover-text-color!important;
}

.searchContainer {
  padding: 0px 15px 0px 15px;
  margin-top: 5px;
  width: 100%;
  height: 41px;
}

.treeContainer {
  //display: contents;
  overflow: auto;
  overflow-x: hidden;
  padding: 0 15px;
  width: 100%;
  min-height: 200px;
}

.smallFont {
  font-size: small;
}

.breakLabel {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 265px;
  display: inline-block !important;
  font-size: 0.8rem !important;
}