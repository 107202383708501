/* stylelint-disable */
@import '../Base/settings.scss';
@import './MarkersGroupHelpers.scss';

.marker-cluster {
    // position: relative;
    border-radius: 40px;

    svg {
        position: absolute;
        z-index: auto;
        width: 80px;
        height: 80px;
    }

    .marker-cluster__count {
        font-size: 14px;
        font-weight: 600;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        // Overrides
        width: auto;
        height: auto;
        margin: 0;

        z-index: 9999;
    }
}

.marker-cluster--colors-alert {
    .cluster-marker-svg__background {
        fill: $alert-badge !important;
    }
}

.marker-cluster--colors-danger {
    .cluster-marker-svg__background {
        fill: $danger-badge !important;
    }
}

.marker-cluster--colors-success {
    .cluster-marker-svg__background {
        fill: $success-badge !important;
    }
}

.marker-cluster--colors-low-connection {
    .cluster-marker-svg__background {
        fill: $low-connection-badge !important;
    }
}

.marker-cluster--colors-person {
    .cluster-marker-svg__background {
        fill: $person-badge !important;
    }
}

.marker-cluster--colors-inactive {
  .cluster-marker-svg__background {
    fill: $inactive-badge !important;
  }
}

/* TWO COLOR CLUSTERS */
.marker-cluster--colors-alert-danger {
    @include beach-ball-2-colors($alert-badge, $danger-badge);
}
.marker-cluster--colors-alert-inactive {
    @include beach-ball-2-colors($alert-badge, $inactive-badge);
}
.marker-cluster--colors-alert-low-connection {
    @include beach-ball-2-colors($alert-badge, $low-connection-badge);
}
.marker-cluster--colors-alert-person {
    @include beach-ball-2-colors($alert-badge, $person-badge);
}
.marker-cluster--colors-alert-success {
    @include beach-ball-2-colors($alert-badge, $success-badge);
}

.marker-cluster--colors-danger-inactive{
    @include beach-ball-2-colors($danger-badge, $inactive-badge);
}
.marker-cluster--colors-danger-low-connection {
    @include beach-ball-2-colors($danger-badge, $low-connection-badge);
}
.marker-cluster--colors-danger-person {
    @include beach-ball-2-colors($danger-badge, $person-badge);
}
.marker-cluster--colors-danger-success {
    @include beach-ball-2-colors($danger-badge, $success-badge);
}

.marker-cluster--colors-inactive-low-connection {
    @include beach-ball-2-colors($inactive-badge, $low-connection-badge);
}
.marker-cluster--colors-inactive-person {
    @include beach-ball-2-colors($inactive-badge, $person-badge);
}
.marker-cluster--colors-inactive-success {
    @include beach-ball-2-colors($inactive-badge, $success-badge);
}

.marker-cluster--colors-low-connection-person {
    @include beach-ball-2-colors($low-connection-badge, $person-badge);
}
.marker-cluster--colors-low-connection-success {
    @include beach-ball-2-colors($low-connection-badge, $success-badge);
}

.marker-cluster--colors-person-success {
    @include beach-ball-2-colors($person-badge, $success-badge);
}

/* THREE COLOR CLUSTERS */
.marker-cluster--colors-danger-person-success {
    @include beach-ball-3-colors($danger-badge, $person-badge, $success-badge);
}
.marker-cluster--colors-alert-danger-inactive {
    @include beach-ball-3-colors($alert-badge, $danger-badge, $inactive-badge);
}
.marker-cluster--colors-alert-danger-low-connection {
    @include beach-ball-3-colors($alert-badge, $danger-badge, $low-connection-badge);
}
.marker-cluster--colors-alert-danger-person {
    @include beach-ball-3-colors($alert-badge, $danger-badge, $person-badge);
}
.marker-cluster--colors-alert-danger-success {
    @include beach-ball-3-colors($alert-badge, $danger-badge, $success-badge);
}
.marker-cluster--colors-alert-inactive-low-connection {
    @include beach-ball-3-colors($alert-badge, $inactive-badge, $low-connection-badge);
}
.marker-cluster--colors-alert-inactive-person {
    @include beach-ball-3-colors($alert-badge, $inactive-badge, $person-badge);
}
.marker-cluster--colors-alert-inactive-success {
    @include beach-ball-3-colors($alert-badge, $inactive-badge, $success-badge);
}
.marker-cluster--colors-alert-low-connection-person {
    @include beach-ball-3-colors($alert-badge, $low-connection-badge, $person-badge);
}
.marker-cluster--colors-alert-low-connection-success {
    @include beach-ball-3-colors($alert-badge, $low-connection-badge, $success-badge);
}
.marker-cluster--colors-alert-person-success {
    @include beach-ball-3-colors($alert-badge, $person-badge, $success-badge);
}

.marker-cluster--colors-danger-inactive-low-connection {
    @include beach-ball-3-colors($danger-badge, $inactive-badge, $low-connection-badge);
}
.marker-cluster--colors-danger-inactive-person {
    @include beach-ball-3-colors($danger-badge, $inactive-badge, $person-badge);
}
.marker-cluster--colors-danger-inactive-success {
    @include beach-ball-3-colors($danger-badge, $inactive-badge, $success-badge);
}
.marker-cluster--colors-danger-low-connection-success {
    @include beach-ball-3-colors($danger-badge, $low-connection-badge, $success-badge);
}

.marker-cluster--colors-inactive-low-connection-person {
    @include beach-ball-3-colors($inactive-badge, $low-connection-badge, $person-badge);
}
.marker-cluster--colors-inactive-low-connection-success {
    @include beach-ball-3-colors($inactive-badge, $low-connection-badge, $success-badge);
}
.marker-cluster--colors-inactive-person-success {
    @include beach-ball-3-colors($inactive-badge, $person-badge, $success-badge);
}

.marker-cluster--colors-low-connection-person-success {
    @include beach-ball-3-colors($low-connection-badge, $person-badge, $success-badge);
}

/* FOUR COLOR CLUSTERS */
.marker-cluster--colors-alert-danger-inactive-low-connection {
    @include beach-ball-4-colors($alert-badge, $danger-badge, $inactive-badge, $low-connection-badge);
}
.marker-cluster--colors-alert-danger-inactive-person {
    @include beach-ball-4-colors($alert-badge, $danger-badge, $inactive-badge, $person-badge);
}
.marker-cluster--colors-alert-danger-inactive-success {
    @include beach-ball-4-colors($alert-badge, $danger-badge, $inactive-badge, $success-badge);
}
.marker-cluster--colors-alert-danger-low-connection-person {
    @include beach-ball-4-colors($alert-badge, $danger-badge, $low-connection-badge, $person-badge);
}
.marker-cluster--colors-alert-danger-low-connection-success {
    @include beach-ball-4-colors($alert-badge, $danger-badge, $low-connection-badge, $success-badge);
}
.marker-cluster--colors-alert-danger-person-success {
    @include beach-ball-4-colors($alert-badge, $danger-badge, $person-badge, $success-badge);
}

.marker-cluster--colors-alert-inactive-low-connection-person {
    @include beach-ball-4-colors($alert-badge, $inactive-badge, $low-connection-badge, $person-badge);
}
.marker-cluster--colors-alert-inactive-low-connection-success {
    @include beach-ball-4-colors($alert-badge, $inactive-badge, $low-connection-badge, $success-badge);
}

.marker-cluster--colors-danger-inactive-low-connection-person {
    @include beach-ball-4-colors($danger-badge, $inactive-badge, $low-connection-badge, $person-badge);
}
.marker-cluster--colors-danger-inactive-low-connection-success {
    @include beach-ball-4-colors($danger-badge, $inactive-badge, $low-connection-badge, $success-badge);
}

.marker-cluster--colors-inactive-low-connection-person-success {
    @include beach-ball-4-colors($inactive-badge, $low-connection-badge, $person-badge, $success-badge);
}

/* FIVE COLOR CLUSTERS */
.marker-cluster--colors-alert-danger-inactive-low-connection-success {
    @include beach-ball-5-colors($alert-badge, $danger-badge, $inactive-badge, $low-connection-badge, $success-badge);
}
.marker-cluster--colors-alert-danger-inactive-low-connection-person {
    @include beach-ball-5-colors($alert-badge, $danger-badge, $inactive-badge, $low-connection-badge, $person-badge);
}
.marker-cluster--colors-danger-inactive-low-connection-person-success {
    @include beach-ball-5-colors($danger-badge, $inactive-badge, $low-connection-badge, $person-badge, $success-badge);
}
.marker-cluster--colors-alert-danger-low-connection-person-success {
    @include beach-ball-5-colors($alert-badge, $danger-badge, $low-connection-badge, $person-badge, $success-badge);
}

/* SIX COLOR CLUSTERS */
.marker-cluster--colors-alert-danger-inactive-low-connection-person-success {
    @include beach-ball-6-colors($alert-badge, $danger-badge, $inactive-badge, $low-connection-badge, $person-badge, $success-badge);
}

/* SEVEN COLOR CLUSTERS */
// @include beach-ball-7-colors($alert-badge, $danger-badge, $success-badge, $alert-badge, $danger-badge, $success-badge, $danger-badge);
