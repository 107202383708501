.owm-icondiv {
  background-color: rgb(211, 211, 211);
  border: 1px solid rgb(169, 169, 169);
  border-radius: 10px;
  opacity: 0.7;
  text-align: center;
  width: 50px;
}

.owm-icondiv-temp {
  color: "black";
  font-weight: bold;
  text-shadow: 0.1em 0.1em 0.1em rgb(255, 255, 255);
  text-shadow: rgb(255, 255) 0.1em 0.1em;
}

.owm-popup-name {
  font-size: 2em;
  font-weight: bold;
}

.owm-popup-name a:link,
.owm-popup-name a:active,
.owm-popup-name a:visited {
  color: rgb(0, 0, 0);
  text-decoration: none;
}

.owm-popup-name a:hover {
  color: rgb(255, 0, 0);
  text-decoration: none;
}

.owm-popup-description {
  font-size: 1.5em;
  font-weight: bold;
}

/* 
.owm-popup-main {
} 
*/

.owm-popup-main img {
  vertical-align: middle;
}

.owm-popup-main span {
  padding-left: 0.5em;
}

.owm-popup-temp {
  font-size: 2em;
  font-weight: bold;
}

/*
.owm-popup-details {
}

.owm-popup-detail {
}
*/
.owm-popup-timestamp {
  color: rgb(128, 128, 128);
  padding-top: 0.5em;
}

/*
.owm-legend-item {
}
*/
.leaflet-popup-content-wrapper,
.leaflet-popup-tip {
  background: rgb(253, 253, 250);
  box-shadow: 0 3px 14px rgb(0 0 0 / 40%);
  color: #333;
  width: max-content;
}
