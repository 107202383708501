@import "../../Theme";

.sectionTitle {
  background-color: $revision-panel-section-title-background-color;
  color: $revision-panel-section-title-text-color;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  height: 45px;
  line-height: 45px;
  padding-left: 20px;
}

.revisionFilterContainer {
  background-color: $revision-panel-filter-container-background-color;
  padding: 15px;
}

.buttonContainerFrm {
  margin-top: 15px;
  text-align: center;
}

.itemRevisionNotice {
  border-bottom: 1px solid #a0a8c5;
  //height: 60px;
  //margin-top: 10px;
  //line-height: 55px;
  cursor: pointer;
}

.revisionIconState {
  height: 24px;
  margin: 9px;
  width: 24px;
}

.listRevisionContainer .MuiPaper-root {
  background-color: $list-revision-title-background-color;
  color: $list-revision-title-text-color;
}

.listRevisionContainer .MuiAccordionDetails-root {
  background-color: $list-revision-content-background-color;
  color: $list-revision-content-text-color;
  display: flex;
  padding: 8px 16px 16px;
}