/* stylelint-disable */
.react-checkbox-tree > ol {
  box-flex: 1;
  flex: 1 1 auto;
  flex: 1 1 auto;
}

.react-checkbox-tree ol {
  list-style-type: none;
  margin: 0;
  padding-left: 0;
}

.react-checkbox-tree ol ol {
  background-color: #2a2a2a;
  padding-left: 24px;
}

.rct-collapse {
  background: none;
  border: 0;
}

.rct-title {
  display: inline-block !important;
  font-size: 0.8rem;
  overflow: hidden;
  padding: 0 5px;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 265px;
}

/* .rct-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 265px;
  display: inline-block !important;
} */
