@import '../../Base/settings.scss';

.MuiButtonBase-root.dropdown-button {
    height: 100%;
    color: $navbar-menu-options-text;

    .navbar-item-button__text {
        padding: 0 $grid-unit * 2;
    }
}
