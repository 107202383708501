@import '../Base/settings.scss';

$marker-cluster-count-size: 60px;
$marker-cluster-count-radius: 40px;


%cluster-marker-transparent {
    .cluster-marker-svg__background {
        fill: transparent !important;
    }
}

%pseudo-element {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
}

@mixin beach-ball-2-colors($color1, $color2) {
    /* Fallback for non conic-gradient browsers */
    background-color: $color1;
    overflow: hidden;

    &:after {
        @extend %pseudo-element;

        left: 50%;
        background-color: $color2;
    }
    /* Fallback for non conic-gradient browsers */

    @supports (background: conic-gradient(red 25%, blue 0)) {
        @extend %cluster-marker-transparent;

        background: conic-gradient($color1 50%, $color2 0);

        /* Remove unnecesary things when conic-gradient is present */
        &:after {
            content: none !important;
        }
        /* Remove unnecesary things when conic-gradient is present */
    }
}

@mixin beach-ball-3-colors($color1, $color2, $color3) {
    /* Fallback for non conic-gradient browsers */
    background-color: $color3;
    overflow: hidden;

    &:before {
        @extend %pseudo-element;

        left: 50%;
        background-color: $color1;
    }

    &:after {
        @extend %pseudo-element;

        top: 50%;
        transform: rotate(45deg);
        background-color: $color2;
    }
    /* Fallback for non conic-gradient browsers */

    @supports (background: conic-gradient(red 25%, blue 0)) {
        @extend %cluster-marker-transparent;

        /* Remove unnecesary things when conic-gradient is present */
        &:before, &:after {
            content: none !important;
        }
        /* Remove unnecesary things when conic-gradient is present */

        background: conic-gradient($color1 33%, $color2 0 66%, $color3 0);
    }
}

@mixin beach-ball-4-colors($color1, $color2, $color3, $color4) {
    /* Fallback for non conic-gradient browsers */
    background-color: $color4;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    &:before {
        @extend %pseudo-element;

        left: 50%;
        bottom: 50%;
        background-color: $color1;
    }

    &:after {
        @extend %pseudo-element;

        top: 50%;
        right: 50%;
        background-color: $color3;
    }

    .marker-cluster__count {
        position: relative;
        border-radius: $marker-cluster-count-radius;
        height: $marker-cluster-count-size;
        width: $marker-cluster-count-size;
    }

    .marker-cluster__count:before {
        @extend %pseudo-element;

        top: 50%;
        left: 50%;
        background-color: $color2;
    }
    /* Fallback for non conic-gradient browsers */

    @supports (background: conic-gradient(red 25%, blue 0)) {
        @extend %cluster-marker-transparent;

        /* Remove unnecesary things when conic-gradient is present */
        &:before, &:after,
        .marker-cluster__count:before {
            content: none !important;
        }
        /* Remove unnecesary things when conic-gradient is present */

        background: conic-gradient($color1 25%, $color2 0 50%, $color3 0 75%, $color4 0);
    }
}

@mixin beach-ball-5-colors($color1, $color2, $color3, $color4, $color5) {
    background-color: $color1;

    // Temporal fix for non conic-gradient supportive browsers
    @supports not (background: conic-gradient(red 25%, blue 0)) {
        @include beach-ball-4-colors($color1, $color2, $color3, $color4);
    }

    @supports (background: conic-gradient(red 25%, blue 0)) {
        @extend %cluster-marker-transparent;

        background: conic-gradient($color1 20%, $color2 0 40%, $color3 0 60%, $color4 0 80%, $color5 0);
    }
}

@mixin beach-ball-6-colors($color1, $color2, $color3, $color4, $color5, $color6) {
    background-color: $color1;

    // Temporal fix for non conic-gradient supportive browsers
    @supports not (background: conic-gradient(red 25%, blue 0)) {
        @include beach-ball-4-colors($color1, $color2, $color3, $color4);
    }

    @supports (background: conic-gradient(red 25%, blue 0)) {
        @extend %cluster-marker-transparent;

        background: conic-gradient(
            $color1 16.67%, $color2 0 33.34%, $color3 0 50%, $color4 0 66.67%, $color5 0 83.35%, $color6 0
        );
    }
}

@mixin beach-ball-7-colors($color1, $color2, $color3, $color4, $color5, $color6, $color7) {
    background-color: $color1;

    @supports (background: conic-gradient(red 25%, blue 0)) {
        @extend %cluster-marker-transparent;

        background: conic-gradient(
            $color1 14.28%, $color2 0 28.56%, $color3 0 42.84%, $color4 0 57.12%, $color5 0 71.4%, $color6 0 85.68%, $color7 0
        );
    }
}
