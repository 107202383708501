@import '../../Base/settings.scss';

.panel-form__inline-group {
    .marker-info__revisions-extra__small-input.MuiFormControl-root {
        width: 40%;
    }

    .MuiIconButton-root.marker-info__revisions-extra__action {
        color: $navbar-menu-options-text;
        margin-right: -$grid-unit * 3;
    }
}
