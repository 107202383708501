@import '../../Base/settings.scss';

.marker-info__notifications {
    .MuiSvgIcon-root.marker-info__notification-icon {
        padding-top: $grid-unit * 2;
        width: 2rem;
        height: 2rem;

        path {
            fill: $alert-badge;
        }
    }

    .MuiSvgIcon-root.marker-info__expand-icon path {
        fill: $navbar-menu-options-text;
    }

    .MuiSvgIcon-root.marker-info__notification-icon--read path {
        fill: $inactive-badge;
    }

    .marker-info__notification-description {
        padding-left: $grid-unit * 2;
    }

    .marker-info__notificaiton-summary {
        color: $navbar-menu-options-text;
    }

    .marker-info__notification-details {
        padding: 0 30px 0 40px;
    }

    .MuiExpansionPanel-root {
        background-color: $expandable-bg-2;
        color: $navbar-text-color;

        .MuiExpansionPanelSummary-root,
        .MuiExpansionPanelDetails-root {
            padding-left: $grid-unit * 2;
            padding-right: $grid-unit * 2;
            overflow: hidden;
        }
    }

    .MuiExpansionPanel-root.Mui-expanded {
        margin: 0;
    }

    .MuiExpansionPanelSummary-content.Mui-expanded {
        margin: $grid-unit * 3 0;
    }

    .MuiExpansionPanel-rounded {
        border-radius: 0 !important;
        border-bottom: none;
        box-shadow: none;
        border-bottom: 1px solid $navbar-menu-options-text;
    }
}
