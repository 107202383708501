@import "../../Theme";

/* COLORS */
$general-colors: #2b6cdb;

$general-colors-selected: $primary;

$brand-background: $app-base; // navbar dropdown background color

$navbar-background: $navbar-background-color;
$navbar-background2: $navbar-background-color;
//$navbar-background2: #1e1e1e;

//$navbar-foreground: #f1f1f1;
$navbar-foreground: $navbar-text-color;
$navbar-text-color: $navbar-dropdown-text-color;
$navbar-menu-options: #2e3279;
$navbar-menu-options-text: $navbar-dropdown-text-color; // navbar dropdown headers text/icon color
$navbar-menu-options-2: #252951;
$navbar-menu-options-3: #2e345a;

$lateral-background: $sidebar-background-color; // navbar and sidebar color
$lateral-navbar-title-bg: $primary; // expandable sidebar window title colors
$lateral-navbar-title-text-color: $primary-contrast; // expandable sidebar window title text colors
$lateral-navbar-section-bg: $primary; // drop down item hover background color
$lateral-navbar-section-bg-selected: $primary; // sidebar icons hover/selected background
$lateral-navbar-section-text-selected-color: $primary-contrast; // sidebar icons hover/selected background

$expandable-bg-1: $custom-expandable-panel-title-background-color;
$expandable-text-1: $custom-expandable-panel-title-text-color;
$expandable-bg-2: $custom-expandable-panel-title-background-color; //#1e1e1e; Segun el template no es este el color de los bkg
$expandable-text-2: $custom-expandable-panel-title-text-color;
$expandable-bg-3: $custom-expandable-panel-title-background-color;
$expandable-text-3: $custom-expandable-panel-title-text-color;
$expandable-bg-4: $custom-input-popper-background-color;

$title-color-1: $brand-secondary-contrast; // expandable sidebar window title text color

$icon-color-green: #348500;

$line-color-1: #6e9aff;
$line-color-2: $red;

$navbar-menu-options-selected: #1d2134;
$navbar-icon-selected: $brand;

$danger-badge: $danger;
$danger-background: #e19d9d;
$danger-foreground: #e19d9d;

$success-badge: $success;
$success-background: #aece99;
$success-foreground: #aece99;

$alert-badge: $warning;
$alert-background: #f1cf99;
$alert-foreground: #f1cf99;

$info-badge: $info;
$info-background: #acb9ff;
$info-foreground: #acb9ff;

$inactive-badge: $inactive;
$inactive-background: #b5bbbf;
$inactive-foreground: #b5bbbf;

$low-connection-badge: $purple;
$low-connection-background: #d2a7dd;
$low-connection-foreground: #d2a7dd;

$person-badge: $blue;
$person-background: #acb9ff;
$person-foreground: #acb9ff;

$far-away-badge: $far-away;
$far-away-background: #acb9ff;
$far-away-foreground: #acb9ff;

/* COLORS FOR MARKERS */
// Watch out the order! these are index accessed elements
$status-list: "low-connection" "success" "danger" "alert" "inactive" "person";
$status-list-badge: $low-connection-badge $success-badge $danger-badge
  $alert-badge $inactive-badge $person-badge;
$status-list-bg: $low-connection-foreground $success-foreground
  $danger-foreground $alert-foreground $inactive-foreground $person-foreground;

/* UNITS */
$grid-unit: 4px;
