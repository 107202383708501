/* stylelint-disable */
@import "../Base/settings.scss";

.ant-picker-dropdown {
  z-index: 9999 !important;
}

.descDates {
  color: $expandable-panel-title-text-color; // #a0a8c5;
  font-size: 11px;
}
