/* stylelint-disable */
@import './settings.scss';

$navbar-size: 64px;
$navbar-size-lateral: 50px;
$navbar-button-height: 53px;
$navbar-title-height: 130px;
$navbar-title-width: 430px;

.MuiAppBar-root {
    &.navbar--lateral {
        background-color: $lateral-background;
        color: $navbar-foreground;
        z-index: 500;
        height: 100%;
        width: $navbar-size-lateral;
        border-right: 1px solid $app-base-contrast;

        .navbar__icon-button {
            width: $navbar-size-lateral;
            height: $navbar-button-height;
            border-radius: 0;
            padding: 0;

            &.navbar__icon-button--selected,
            &:focus,
            &:hover {
                // background-color: $navbar-icon-selected;
                background-color: $lateral-navbar-section-bg-selected;
            }

            // svg {
            //     width: 2rem;
            //     height: 2rem;

            //     // Some icons have multiple paths inside an svg.
            //     // The last one seems to be the one that has the actual icon.
            //     path:last-child {
            //         fill: $navbar-text-color;
            //     }
            // }
        }

        > div {
            width: $navbar-size-lateral;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 0;
        }
    }
}
