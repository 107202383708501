/* stylelint-disable */
@import "../settings";

$dropdown-list-height: 350px;
$dropdown-list-width: 450px;

.dropdown-list {
  .MuiMenu-paper {
    background-color: $navbar-background;
  }

  .MuiPaper-rounded {
    border-radius: 0;
  }

  .MuiMenu-list {
    padding: 0;
    width: $dropdown-list-height;
    max-height: $dropdown-list-width;
    overflow: auto;

    li.dropdown-list__header {
      padding: 0;
      margin: 0;
      cursor: default;
      background-color: $navbar-dropdown-header-background-color;

      > div {
        width: 100%;
      }

      &.MuiListItem-button:hover {
        background-color: $navbar-dropdown-header-hover-background-color;
      }
    }

    li:not(.dropdown-list__header) {
      color: $navbar-menu-options-text;
      background-color: $navbar-dropdown-background-color;

      &:hover {
        background-color: $navbar-dropdown-hover-background-color;
      }
      svg path {
        fill: $navbar-menu-options-text;
      }
    }

    //
    li.dropdown-list__item {
      padding: 0;
      margin: 0;
      cursor: default;
      background-color: $navbar-dropdown-background-color;

      > div {
        width: 100%;
      }

      &.MuiListItem-button:hover {
        background-color: $navbar-dropdown-hover-background-color;
      }
    }

    li:not(.dropdown-list__item) {
      color: $navbar-menu-options-text;
      background-color: $navbar-dropdown-background-color;

      &:hover {
        background-color: $navbar-dropdown-hover-background-color;
      }
      svg path {
        fill: $navbar-menu-options-text;
      }
    }
  }

  li.Mui-focusVisible,
  li:hover:not(.dropdown-list__header) {
    background-color: $lateral-navbar-section-bg;
    color: white;

    svg path {
      fill: white;
    }
  }

  li:hover:not(.dropdown-list__item) {
    background-color: $lateral-navbar-section-bg;
    color: white;

    svg path {
      fill: white;
    }
  }

  .MuiListItem-root.dropdown-item {
    padding: 0;
    justify-content: center;
  }
}
