/* stylelint-disable */
@import '../Base/settings.scss';

$userAvatarSize: 100px;

.users-button__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.users-button__avatar, .users-button__avatar > img {
    width: $userAvatarSize;
    height: $userAvatarSize;
    border-radius: 50%;
    background-color: $expandable-bg-3;
    flex: 0 0 auto;
}

.users-button__info {
    color: $navbar-menu-options-text;
    padding-left: $grid-unit * 2;
    padding-right: $grid-unit * 2;
    overflow: hidden;

    div {
        text-overflow: ellipsis;
        overflow: hidden;
    }
}
