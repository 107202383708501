/* stylelint-disable */
@import './settings.scss';

$subtitle-height: 3rem;

.tab-panel__content {
    // flex: 1 1 auto;
    max-height: 100%;
    // overflow: auto;
    position: relative;

    > div {
        // overflow: auto;
        flex: 1 1 auto;
        max-height: calc(100% - #{$subtitle-height});
        margin-top: $subtitle-height;
    }
}

.MuiAppBar-positionStatic.tab-panel__content-subtitle {
    color: $tab-subtitle-text-color!important;
    background-color: $tab-subtitle-background-color;
    padding: $grid-unit * 3;
    height: $subtitle-height;
    display: flex;
    justify-content: center;
    position: absolute;
    top: 0;
    font: normal normal normal 14px/17px Roboto;
    letter-spacing: 0px;
    color: $title-color-1;
}
