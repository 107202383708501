/* stylelint-disable */
@import "../../Theme.scss";
@import "./settings.scss";

$navbar-size: 64px;
$navbar-size-lateral: 50px;
$navbar-title-height: 130px;
$navbar-title-width: 430px;
$navbar-search-input-text-color: $app-base-text-color;

.MuiAppBar-root {
  &.navbar--top {
    width: 100%;
    height: $navbar-size;
    background-color: $brand-logo-background;
    color: $navbar-foreground;
    z-index: 500;
    display: flex;
    flex-direction: row;
  }

  .navbar__title {
    width: $navbar-title-width;
    // background-color: $brand-background;
    display: flex;
    align-items: center;
    justify-content: center;

    a {
      color: white;
      text-decoration: none;
      font-size: 0;
      line-height: 0;
    }
  }

  .navbar__content {
    background-color: $navbar-background;
    max-height: $navbar-size;
    display: flex;
    justify-content: space-between;
    flex: 1 1 auto;
    padding-right: 0;
  }

  .navbar__content-search {
    min-width: $navbar-title-width;
    flex: 0 1 auto;
  }

  .navbar__content-search label,
  .navbar__content-search .MuiInput-formControl {
    color: $navbar-search-input-text-color;
  }

  .navbar__content-menu {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;

    > div {
      position: relative;
      padding: 0 $grid-unit * 2;

      &:after {
        content: "";
        width: 1px;
        background-color: $navbar-menu-options-text;
        position: absolute;
        right: 0;
        top: 15px;
        bottom: 15px;
      }

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;

        &:after {
          content: none;
        }
      }
    }

    .MuiButtonBase-root.dropdown-button .dropdown-button__principal-icon {
      width: 2rem;
      height: 2rem;
    }
  }
}

.searchCategory {
  width: 100%;
  background-color: $search-category-background-color !important;
  color: $search-category-text-color !important;
  padding: 10px 10px 10px 25px;
}

.catLabel {
  color: whitesmoke;
  //padding-left: 15px;
}

.searchListbox {
  background-color: $search-option-background-color;
}

.searchOption {
  color: $search-option-text-color;
  width: 100%;
  padding: 10px 10px 10px 25px;
}

.searchOption:hover {
  background-color: $search-option-hover-background-color;
  color: $search-option-hover-text-color;
}

.searchListbox li {
  border-bottom: 1px solid gray;
  padding: 0;
}

.searchStatus {
  width: 5px !important;
  height: 5px !important;
  border-radius: 50% !important;
  display: inline-block !important;
  margin-right: 15px !important;
  // background-color: red;

  &.type_danger {
    background-color: $danger-badge;
  }

  &.type_success {
    background-color: $success-badge;
  }

  &.type_alert {
    background-color: $alert-badge;
  }

  &.type_low-connection-badge {
    background-color: $low-connection-badge;
  }

  &.type_inactive {
    background-color: $inactive;
  }
}
