@import '../Base/settings.scss';

.marker-popup {
    &.marker-popup {
        left: 0 !important;
    }

    .leaflet-popup-content-wrapper,
    .leaflet-popup-tip {
        background-color: $navbar-background;
        color: white;
    }

    .leaflet-popup-content-wrapper {
        border-radius: 0;
        padding: 0;
    }

    .leaflet-popup-tip-container {
        left: 0;
        margin-left: 0;
        width: 20px;

        .leaflet-popup-tip {
            width: 20px;
            height: 20px;
            margin: -10px;
            padding: 0;
        }
    }

    .leaflet-popup-close-button {
        display: none;
    }

    /* Variants */
    @each $status in $status-list {
        $i: index($status-list, $status);

        &.marker-popup--#{$status} {
            .leaflet-popup-tip {
                background-color: nth($status-list-badge, $i);
            }
        }
    }
}
