@import '../../Base/settings.scss';

.marker-info__revisions-icon--danger path {
    fill: $danger-badge;
}

.marker-info__revisions-icon--alert path {
    fill: $alert-badge;
}

.marker-info__revisions-icon--success path {
    fill: $success-badge;
}

.marker-info__revisions-description {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.marker-info__revisions-summary {
    flex: 1 1 auto;
    justify-self: flex-start;
    padding: 0 $grid-unit * 2;
}

.marker-info__revisions-date {
    flex: 0;
}
