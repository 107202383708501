.filterReportButtons {
  background-color: rgb(128, 128, 128);
  margin-top: 20px;
  padding: 20px 10px;
}

.resultPart {
  margin: 20px 0;
  padding: 20px;
}
