/* stylelint-disable */
@import '../Base/settings.scss';

.marker-icon {
    background: transparent;
    border: none;
    margin-left: -11px !important;
    margin-top: -36px !important;
}

.pointer-icon {
    background: transparent;
    border: none;
    margin-top: -13px !important;
}

.arrow-icon {
    background: transparent;
    border: none;
    margin-top: -9px !important;
    margin-left: -9px !important;
}