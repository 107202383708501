@import "../../Theme";

.dialogConfirmationTitle {
  background-color: #2b6cdb;
}

.dialogConfirmationTitle > * {
  color: #fff9;
  font-family: "Roboto", sans-serif;
  font-size: 21px;
}

.dialogConfirmationButtonContainer {
  bottom: 37px;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  text-align: center;
  width: 100%;
}

.dialogConfirmationContent {
  background-color: #121212;
  height: 188px;
  padding-top: 47px !important;
  position: relative;
  text-align: center;
}

.dialogConfirmationText {
  color: $primary-contrast;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
}
