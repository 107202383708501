@import '../../Base/settings.scss';

.expandable-panel__actions-block {
    .period-select {
        display: flex;
        flex: 1 1 auto;
    }

    .extra-filter {
        margin: 0;
        flex: 0 1 auto;
        width: 95px;
    }
}
