@import './settings.scss';

$input-height: 40px;
$input-font-size: 16px;

.styled-select__wrapper {
    height: 100%;

    &.form-control {
        background-color: unset;
    }

    &.text-color--white {
        label,
        select,
        .MuiInputBase-inputSelect {
            color: $navbar-menu-options-text !important;
        }
    }

    .MuiSvgIcon-root path:last-child {
        fill: $navbar-menu-options-text;
    }
}

.styled-select__menu--lateral-panel.dropdown-list {
    .MuiMenu-list {
        width: 380px; // Lateral panel width
    }

    .MuiMenuItem-root {
        padding-top: $grid-unit * 3;
        padding-bottom: $grid-unit * 3;

        &:not(:last-child) {
            border-bottom: 1px solid $navbar-menu-options-text;
        }
    }

    &.styled-select__menu--depth-1 {
        .MuiMenu-paper {
            left: 50px !important; // Lateral menu width
        }
    }

    &.styled-select__menu--depth-2 {
        .MuiMenu-paper {
            left: 430px !important; // Lateral menu width + lateral panel width
        }
    }
}
