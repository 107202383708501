/* stylelint-disable */
@import "./settings.scss";

$expandable-panel-transition: 240ms;
$expandable-panel-width: 380px;
$expandable-panel-left: 50px;
$expandable-panel-openNotification: 820px;
$expandable-panel-journeyReport: 570px;

.expandable-panel {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 0;
  background-color: $expandable-bg-2;
  color: $navbar-menu-options-text;
  overflow: auto;

  transition: width $expandable-panel-transition ease-in-out;
}

.expandable-panel--left--level-1 {
  left: $expandable-panel-left;
  z-index: 2;
  box-shadow: 3px 0px 4px rgba(0, 0, 0, 0.3);
}

.expandable-panel--left--level-2 {
  left: $expandable-panel-left + $expandable-panel-width;
  background-color: $expandable-bg-2;
  z-index: 1;
}

.expandable-panel--left--level-3 {
  left: $expandable-panel-left + $expandable-panel-openNotification;
  background-color: $expandable-bg-2;
  z-index: 1;
}

.expandable-panelAux-Revision {
  left: $expandable-panel-left + $expandable-panel-journeyReport;
  background-color: $expandable-bg-2;
  z-index: 1;
}

.expandable-panel--open {
  width: $expandable-panel-width;
}

.expandable-panel--openNotification {
  width: $expandable-panel-openNotification;
  overflow-x: clip;
  height: 100vh; // fit-content
}

.expandable-panel--journeyReport {
  width: $expandable-panel-journeyReport;
}

.expandable-panel--openDrivingBehavior {
  width: calc(100vw - 50px);
}

.expandable-panel__title {
  background: $expandable-panel-title-background-color;
  color: $expandable-panel-title-text-color;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;

  svg path {
    fill: $expandable-panel-title-text-color;
  }

  .expandable-panel__title-text {
    flex: 1 1 auto;
    padding-left: $grid-unit * 4;
    color: white;
  }

  .expandable-panel__close-btn {
    flex: 0 1 auto;
  }
}

.expandable-panel__content {
  position: absolute;
  top: 0;
  bottom: 0;
  width: $expandable-panel-width;
  display: flex;
  flex-direction: column;
  max-height: 100%;
}

.expandable-panel__actions__button.MuiIconButton-root {
  color: $red;
  margin-right: -$grid-unit * 2;
}

.title-format {
  font: normal normal bold 16px/19px Roboto;
  letter-spacing: 0px;
  color: $expandable-panel-title-text-color!important;
  opacity: 1 !important;
}
