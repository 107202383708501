@import './settings.scss';

.notification {
    .MuiSnackbar-anchorOriginTopCenter {
        top: 70px;
    }

    .notification-message--success {
        .MuiSnackbarContent-root {
            background-color: $success-badge;
        }
    }

    .notification-message--danger {
        .MuiSnackbarContent-root {
            background-color: $danger-badge;
        }
    }

    .notification-message--alert {
        .MuiSnackbarContent-root {
            background-color: $alert-badge;
        }
    }

    .notification-message--info {
        .MuiSnackbarContent-root {
            background-color: $info-badge;
        }
    }

    @media (min-width: 600px) {
        .MuiSnackbar-anchorOriginTopCenter {
            top: 70px;
        }
    }
}
