@import "../settings.scss";

.MuiPickersBasePicker-pickerView {
  background: $expandable-bg-3;

  .MuiTypography-root {
    color: $navbar-foreground;
  }

  .MuiButtonBase-root:hover {
    background: $expandable-bg-4;
  }

  .MuiPickersCalendarHeader-switchHeader {
    .MuiButtonBase-root {
      background: $expandable-bg-3;
      color: $navbar-foreground;

      &:hover {
        background: $expandable-bg-4;
      }
    }
  }
}

.MuiDialogActions-root {
  background: $expandable-bg-3;

  .MuiButtonBase-root {
    background: $expandable-bg-3;
    color: $navbar-foreground;

    &:hover {
      background: $expandable-bg-2;
    }
  }
}
