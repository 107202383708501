@import "../../../Theme";

.infoBlockDark {
  background-color: $daysteps-report-container-background-color!important;
  & .expandable-panel__tabs-item{
    background-color: $daysteps-report-title-background-color;
    color: $daysteps-report-title-text-color;
  }

  & .MuiSvgIcon-root{
    color: $app-base-text-color;
  }
}