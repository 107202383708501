@import "../../Theme";

.custom-login-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 100px;
    margin-top: 100px;

    &.MuiPaper-root {
        background-color: $login-container-background-color;
        color: $login-container-text-color;
    }

  .MuiFormLabel-root {
    color: $login-container-input-label-color;

    &.Mui-focused {
      color: $login-container-input-focused-label-color;
    }
  }

  .MuiInputBase-root {
    background-color: $login-container-input-background-color;
    color: $login-container-input-text-color;
  }
}

.custom-login-container-panel{
    float: left;
}