/* stylelint-disable */

@import './settings.scss';

.custom-expansion-panel {
    .MuiSvgIcon-root.custom-expansion-panel__expand-icon path {
        fill: $navbar-menu-options-text;
    }

    .MuiExpansionPanel-root {
        background-color: $expandable-bg-1;
        color: $navbar-text-color;

        .MuiExpansionPanelSummary-root,
        .MuiExpansionPanelDetails-root {
            padding-left: $grid-unit * 4;
            padding-right: $grid-unit * 4;
        }
    }

    .MuiExpansionPanel-root.Mui-expanded {
        margin: 0;
    }

    .MuiExpansionPanelSummary-content.Mui-expanded {
        margin: $grid-unit * 3 0;
    }

    .MuiExpansionPanel-rounded {
        border-radius: 0 !important;
        border-bottom: none;
        box-shadow: none;
        border-bottom: 1px solid $navbar-menu-options-text;
    }
}

.subtitlePanelDB {
  font-family: "Roboto", sans-serif;
  font-size: 25px;
  color: #ffffff;
}