@import "../../Theme";

/* stylelint-disable */
$navbar-size: 50px;

.structure {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: stretch;
  overflow: hidden;
}

.structure__body {
  display: flex;
  background-color: $app-base;
  &.structure__body--internal-scroll {
    height: calc(100vh - #{$navbar-size});
  }
}

.structure__main {
  overflow: auto;
  width: 100%;
}

.structure__navigation {
  position: relative;
  width: $navbar-size;
  z-index: 500;
}

.image_upload {
  //   margin: 0;
  padding: 10px;
  align-items: center;
  align-content: space-around;
  
  & label, & label:hover{
    background-color: $btn-primary-background-color;
    color: $btn-primary-text-color;
    border: 1px solid darken($brand, 10%);
  }
  //   position: absolute;
  //   top: 50%;
  //   left: 50%;
  //   -ms-transform: translate(-50%, -50%);
  //   transform: translate(-50%, -50%);
}
