@import './settings.scss';

.panel-form {
    padding: $grid-unit * 6 $grid-unit * 5;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    hr {
        width: 100%;
        margin: 0 0 $grid-unit * 6;
        color: $navbar-menu-options-text;
    }

    .panel-form__inline-group {
        > div:not(:last-child) {
            padding-right: $grid-unit * 4;
        }
    }

    .MuiFormControl-root {
        width: 100%;
    }

    .MuiFormControl-root,
    .MuiIconButton-root {
        padding-bottom: $grid-unit * 8;
    }

    .MuiFormLabel-root,
    .MuiFormControl-root,
    .MuiFormControl-root input {
        color: $navbar-menu-options-text !important;
    }

    .MuiInputBase-input {
        background-color: $expandable-bg-3;
        padding: $grid-unit * 3 $grid-unit * 2;
    }

    .MuiFormLabel-root {
        /*
         * Label is transformed to be 75% of the original size so we add a 25%
         * more space to the right to allow it to be a one line text.
         */
        right: -25%;
    }

    label + .MuiInput-formControl {
        margin-top: $grid-unit * 6;
    }

    /* Google autofill replacement */
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        -webkit-text-fill-color: $navbar-menu-options-text !important;
        -webkit-box-shadow: 0 0 0 30px $expandable-bg-3 inset !important;
    }
}
