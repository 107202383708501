@import "../../Theme";

/* stylelint-disable */
.remoteTableHeader {
  background-color: $remote-table-title-background-color;
  color: $remote-table-title-text-color;
  //text-shadow: #272727;
  height: 60px;
  font-family: "Roboto";
  font-size: 14px;
  font-style: normal;
}

.table-bordered {
  border: 0 !important;
}

.remoteTableHeader > th {
  vertical-align: middle !important;
  font-weight: lighter !important;
  //border: 1px solid #ffffff !important;
  border-bottom: 0 !important;
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
}

.remoteTableBody {
  background-color: $remote-table-body-background-color;
  color: $remote-table-body-text-color;
  height: 30px;
  font-family: "Roboto";
  font-size: 14px;
  font-style: normal;
}

.remoteTableBody > tr > td {
  //border-bottom: 1px solid #707070 !important;
  border-bottom: 1px solid $remote-table-row-border;
  border-right: 0;
  border-left: 0;
  border-top: 0;
  vertical-align: baseline !important;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.page-link {
  position: relative;
  display: block;
  //padding: 0 0.6875rem;
  margin-left: -0.0625rem;
  line-height: 1.25;
  color: #ffffff;
  background-color: transparent;
  border: 0.0625rem solid #3f51b5 !important;
}
.page-link:hover {
  z-index: 2;
  color: #6c757d;
  text-decoration: none;
  background-color: #e3e3e3;
  border-color: #e3e3e3;
}
.page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: none;
}
.page-link:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.page-item.active .page-link {
  z-index: 1;
  color: #ffffff;
  background-color: #3f51b5;
  //border-color: #e14eca;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: transparent;
  border-color: #e3e3e3;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 0.99925rem;
  line-height: 1.625rem;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.4285rem;
  border-bottom-left-radius: 0.4285rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.4285rem;
  border-bottom-right-radius: 0.4285rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.75rem;
  line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2857rem;
  border-bottom-left-radius: 0.2857rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2857rem;
  border-bottom-right-radius: 0.2857rem;
}

.react-bootstrap-table-pagination-total {
  color: #ffffff;
}

.react-bootstrap-table-pagination {
  width: 100%;
}

.searchTableBox > .MuiFilledInput-root {
  padding-bottom: 15px !important;
  width: 100%;
}

.MuiFilledInput-underline:after,
.MuiFilledInput-underline:before {
  display: none;
}

.btnCustomPagination {
  border: 0;
  border-radius: 6px;
  background-color: $remote-table-pagination-unactive-link-background-color;
  color: $remote-table-pagination-unactive-link-text-color;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  margin-left: 7px;
  width: 20px;
  height: 25px;
}

.btnCustomPaginationActive {
  background-color: $remote-table-pagination-active-background-color;
  color: $remote-table-pagination-active-text-color;
}

.pageSizeContainerSelector {
  width: 70px;
  display: inline-block;
}

.customSearchTable,
.customSearchTable:focus {
  background-color: transparent;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 1px solid;
  box-shadow: none;
  color: $app-base-text-color;
  &::placeholder {
    color: $app-base-text-color;
  }
}

.customSearchTable:focus {
  border-bottom: 1px solid $input-highlight;
}
