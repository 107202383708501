/* stylelint-disable */
@import "../../Base/settings.scss";

.expandable-panel__info-block {
  &--dark {
    background-color: $expandable-bg-1;
  }

  .expandable-panel__info-block-content {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    align-items: flex-start;
  }

  .form-control {
    // width: 100%;

    label,
    .MuiInput-input {
      color: $navbar-menu-options-text;
    }
  }
}

.expandable-panel__actions-block {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: $grid-unit * 2;
}

.expandable-panel__tabs-item {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: $grid-unit * 2 $grid-unit * 3 $grid-unit * 2 2%;
}

.expandable-panel__actions-block {
  .form-control {
    flex: 1 1 auto;
  }

  > div:first-child {
    padding-right: 10px;
  }
}
