@import '../../Base/settings.scss';

.dropdown {
    height: 100%;
}

.MuiButton-root {
    &.navbar-item-button {
        color: $navbar-menu-options-text;
    }

    .navbar-item-button__text {
        padding: 0 $grid-unit * 2;
    }
}
