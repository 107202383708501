@import './settings.scss';

.flex-div {
    display: flex;

    &.flex-div--full {
        width: 100%;
    }

    &.flex-div--half {
        width: 50%;
    }

    &.flex-div--column {
        flex-direction: column;
    }

    &.flex-div--center {
        justify-content: center;
        align-items: center;
    }

    &.flex-div--space-between {
        justify-content: space-between;
        align-items: center;
    }

    &.flex-div--bot-divider {
        border-bottom: 1px solid $navbar-menu-options-text;
    }
}
