/* stylelint-disable */
@import "../Base/settings.scss";

.fullContainer {
  box-sizing: border-box;
  width: 100vw;
  height: 100vh;
  background-attachment: fixed;
  background-color: $login-screen-background-color;
  @if($login-screen-background-image != ""){
    background-image: url($login-screen-background-image);
  }
  overflow-y: scroll;
  overflow-x: hidden;
}

.login-container {
  padding: 15px 35px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  width: 335px;
  height: 615px;
  //background-image: url('/assets/img/background.png');

  img {
    width: 100%;
  }

  &.MuiPaper-root {
    background-color: $login-container-background-color;
    color: $login-container-text-color;
  }

  label + .MuiInput-formControl {
    margin-top: $grid-unit * 6;
  }

  .MuiFormLabel-root {
    color: $login-container-input-label-color;

    &.Mui-focused {
      color: $login-container-input-focused-label-color;
    }
  }

  .MuiInputBase-root {
    background-color: $login-container-input-text-color;
    padding: $grid-unit * 2;
  }
}

#username-login-field, #password-login-field, #cargoTrackingNumber {
  color: $login-container-input-text-color !important;
}

.login__form {
  text-align: center;
  // padding-bottom: 20px;
}

.login-container__submit {
  padding-top: $grid-unit * 7;
  width: 100%;
}

.login-container__link {
  text-align: center;
  color: $navbar-text-color;
  text-decoration: none;
}
