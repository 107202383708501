@import "../Base/settings";

.marker {
  .navigation-svg__drawing {
    fill: #fff;
  }

  @each $status in $status-list {
    $i: index($status-list, $status);

    &.marker--#{$status} svg {
      .navigation-svg__drawing {
        fill: nth($status-list-bg, $i);
      }

      .navigation-svg__circle {
        fill: nth($status-list-badge, $i);
      }
    }
  }
}

.tooltip {
  background-color: #fff;
  border: 0;
  border-radius: 4px;
  color: #000;
  font-size: 12px;
  font-weight: 700;
  padding: 4px;
  text-align: center;
}
