$scrollbar-background: #373737;
$scrollbar-bar: #858585;

// Internet Explorer way
* {
    scrollbar-base-color: $scrollbar-bar;
    scrollbar-face-color: $scrollbar-bar;
    scrollbar-3dlight-color: $scrollbar-bar;
    scrollbar-highlight-color: $scrollbar-bar;
    scrollbar-track-color: $scrollbar-background;
    scrollbar-arrow-color: $scrollbar-background;
    scrollbar-shadow-color: $scrollbar-background;
}

// Webkit way
// https://css-tricks.com/custom-scrollbars-in-webkit/
::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-track {
    background-color: $scrollbar-background;
}

::-webkit-scrollbar-thumb {
    background-color: $scrollbar-bar;
}

// ::-webkit-scrollbar-button {}
// ::-webkit-scrollbar-track {}
// ::-webkit-scrollbar-track-piece {}
// ::-webkit-scrollbar-thumb {}
// ::-webkit-scrollbar-corner {}
// ::-webkit-resizer {}

// Firefox Way
// https://stackoverflow.com/questions/6165472/custom-css-scrollbar-for-firefox/54101063#54101063
* {
  scrollbar-color: $scrollbar-bar $scrollbar-background;
  scrollbar-width: thin;
}
