/* stylelint-disable */
@import "../Base/settings.scss";
@import "../../Theme.scss";

.notification-description {
  font-size: small;
  width: 55%;
}

.list-notif-paginated {
  // height: 100vh;
  overflow: auto !important;
}

.list-notif-paginated .table td,
.table th {
  border: 0;
  padding: 0;
}

.list-notif-paginated .react-bootstrap-table-page-btns-ul {
  float: none;
  justify-content: center;
}

.notificationButtonContainer {
  text-align: center;
  width: 10%;
}

.smallFont span {
  font-size: small;
}

.readedFont {
  //color: gray;
  color: rgb(128, 128, 128);
}

.alarmsData {
  width: 25%;
}

.notificationFilterContainer {
  background-color: $filter-panel-background-color;
  padding: 15px;
}

.switchFilter .MuiTypography-body1 {
  font-size: 0.8rem;
}

.switchFilter .MuiSwitch-track {
  background-color: $navbar-text-color;
}

.switchFilter .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
  background-color: $lateral-navbar-section-bg-selected;
}

.btnActionNotification {
  background-color: transparent;
  border: 0;
  &:hover {
    background-color: $notification-btn-action-hover-background-color;
    color: $notification-btn-action-hover-text-color;
    border-radius: 50%;
  }
}

.iconNotificationFilters {
  width: 28px;
  height: 28px !important;
}

.labelFilterNotification {
  color: $app-base-text-color;
  font-size: 14px;
  font-style: normal;
  cursor: pointer;
  padding: 5px 0 0 10px;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 0.99925rem;
  line-height: 1.625rem;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.4285rem;
  border-bottom-left-radius: 0.4285rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.4285rem;
  border-bottom-right-radius: 0.4285rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.75rem;
  line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2857rem;
  border-bottom-left-radius: 0.2857rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2857rem;
  border-bottom-right-radius: 0.2857rem;
}

.react-bootstrap-table-pagination-total {
  color: #ffffff;
}

.react-bootstrap-table-pagination {
  width: 100%;
}

#listNotificationPaginationContainer {
  width: 100%;
  display: flex;
  justify-content: center;
}

.btnCustomPagination {
  border: 0;
  border-radius: 6px;
  background-color: $remote-table-pagination-unactive-link-background-color;
  color: $remote-table-pagination-unactive-link-text-color;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  margin-left: 7px;
  width: 20px;
  height: 25px;
}

.btnCustomPaginationActive {
  background-color: $remote-table-pagination-active-background-color;
  color: $remote-table-pagination-active-text-color;
}

.noData {
  color: $app-base-text-color;
}
