/* stylelint-disable */
@import '../../Base/settings.scss';

.MuiAppBar-root.expandable-panel__tabs {
    background: $lateral-navbar-title-bg;

    .MuiTab-root {
        min-width: auto;
        flex: 1 1 auto;
    }

    .MuiButtonBase-root {
        background-color: $lateral-navbar-section-bg;
    }

    .MuiButtonBase-root.Mui-selected {
        background-color: $lateral-navbar-section-bg-selected;
    }

    .MuiTabs-indicator {
        display: none;
    }
}

.expandable-panel__content-wrapper {
    flex: 1 1 auto;
    overflow: visible;
    display: flex;
    flex-direction: column;
}
