// Base colors
// Shades
$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #2d2d2d !default;
$gray-1000: #121212 !default;
$black: #191919 !default;

// Colors
$blue: #2b6cdb !default;
$darkblue: #1c3663 !default;
$indigo: #6610f2 !default;
$purple: #8e24aa !default;
$pink: #d63384 !default;
$red: #b50909 !default;
$orange: #db8700 !default;
$yellow: #db8700 !default;
$green: #348500 !default;
$teal: #20c997 !default;
$cyan: #0dcaf0 !default;
$transparent: transparent !default;

// Custom colors

$custom-app-base: #333;
$custom-app-base-secondary: #424242;
$custom-app-base-text-color: #A0A8C5;
$custom-lateral-navbar-icons-color: #909090;
$custom-slide-panel-content-background-color: $gray-1000;
$custom-remote-table-title-background-color: $gray-900;
$custom-remote-table-title-text-color: $gray-500;
$custom-remote-table-body-background-color: $gray-1000;
$custom-remote-table-body-text-color: $gray-100;
$custom-remote-table-pagination-unactive-link-text-color: $gray-500;
$custom-revision-panel-filter-container-background-color: $gray-1000;
$custom-dark-marker-popup-title-background-color: $gray-1000;
$custom-dark-marker-popup-content-background-color: #2f2f2f;
$custom-filter-panel-background-color: $gray-1000;
$custom-expandable-panel-title-background-color: #272727;
$custom-expandable-panel-title-text-color: #FFF;
$custom-expandable-panel-subtitle-background-color: #121212;
$custom-expandable-panel-subtitle-text-color: #A0A0A0;
$custom-input-base-background-color: #2f2f2f;
$custom-group-panel-background-color: #424242;
$custom-group-panel-text-color: $white;
$custom-group-panel-action-text-color: $white;
$custom-tab-base-background-color: #00102b;
$custom-input-popper-background-color: #424242;
$custom-input-popper-text-color: $white;
$custom-input-text-color: $white;

// Brand colors
$brand: $blue !default;
$brand-secondary: $darkblue !default;
$brand-contrast: $black;
$brand-secondary-contrast: $white;

// Semantic colors
$primary: $blue !default;
$primary-contrast: #AAC4F1 !default;
$primary-level-2: #204686 !default;
$primary-level-2-contrast: $white !default;
$primary-selected: darken($primary, 10%);
$primary-hover: darken($primary, 15%);
$secondary: $darkblue !default;
$secondary-contrast: #AAC4F1;
$secondary-level-2: #204686 !default;
$secondary-level-2-contrast: $white;
$success: $green !default;
$info: $blue !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-100 !default;
$dark: $gray-900 !default;
$inactive: #47565e;
$far-away: #acb9ff;
$accent: $blue;
$accent-contrast: $white;

//===== Definitions =====//

//===== App =====//
$app-base: $custom-app-base;
$app-base-contrast: $white;
$app-base-secondary: $custom-app-base-secondary;
$app-base-secondary-contrast: $white;
$app-base-subtitle: $gray-700;
$app-base-contrast: $gray-600;
$app-base-text-color: $custom-app-base-text-color;//#A0A8C5; $white;
$app-base-secondary-text-color: $black;
$app-base-subtitle-text-color: $black;

//===== Navbar =====//
$navbar-background-color: $black;
$navbar-text-color: $white;

//===== Lateral Navbar =====//
$lateral-navbar-icons-color: $custom-lateral-navbar-icons-color;

//===== Sidebar =====//
$sidebar-background-color: $navbar-background-color;
$sidebar-text-color: $navbar-text-color;

// Brand logo
$brand-logo-background: $black;

//===== Tab panel =====//
$tab-base-background-color: $custom-tab-base-background-color;
$tab-base-text-color: $white;
$tab-selected-background-color: $transparent;
$tab-selected-text-color: $white;
$tab-subtitle-background-color: $primary;
$tab-subtitle-text-color: $white;

//===== Login page =====//
$login-screen-background-color: $app-base;
$login-container-background-color: rgba(0, 0, 0, 0.7);
$login-container-text-color: $white;
$login-container-input-background-color: $white;
$login-container-input-text-color: $black;
$login-container-input-label-color: #7f7f7f;
$login-container-input-focused-label-color: $white;
$login-screen-background-image: "/assets/img/background.png";