/* stylelint-disable */
.base-map--full {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
}

.base-map--multiple-tracking {
  // position: absolute;
  top: 0;
  left: 0;
  height: 45vh;
}

.divSquare {
  width: 49.5%;
  // height: 200px;
  margin: 4px;
  border: 1px solid black;
  float: left;
}

.mapTrackingContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
  margin: 4px;
}

.leaflet-control-zoom-in {
  background-image: url("/assets/img/zoom-in.png");
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 4px;
  border: 2px solid #2a2a2a;
  margin-bottom: -3px;
}

.leaflet-control-zoom-out {
  background-image: url("/assets/img/zoom-out.png");
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 4px;
  border: 2px solid #2a2a2a;
  margin-bottom: -3px;
}

.leaflet-touch .leaflet-bar a {
  width: 40px;
  height: 40px;
  line-height: 30px;
}

/*.leaflet-control-measure {
  margin-bottom: 200px !important;
}*/

.leaflet-measure-resultpopup {
  width: 300px;
}

.leaflet-control-measure .tasks,
.leaflet-measure-resultpopup .tasks {
  text-align: left !important;
}

.leaflet-control-playback {
  margin-top: 100px !important;
}

.leaflet-bottom {
  margin-bottom: 9% !important;
}

.leaflet-control-trace {
  position: absolute;
  background-color: aquamarine;
}
